import React, {useState, useEffect} from 'react';
import SubirLiquidacion from './SubirLiquidacion';
import { useGetLiquidaciones } from '../../../hooks/useGetCodes';
import { LoadingCard } from '../../../../components/loading/Loadings';
import { PDFDownloadLink } from '@react-pdf/renderer';
import FacturaComision from '../../../../components/documents/FacturaComision';
import format from 'date-fns-tz/format';
import { fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import ImgPdf from '../../../../img/icons/file-text.svg';
import ComissionToWallet from './ComissionToWallet';
import { SelectorOp } from '../../../../components/inputs/Selector';
import { Input } from '../../../../components/inputs/Input';
import SVG from 'react-inlinesvg';

const Liquidaciones = (props) => {

	const {
		id,
		ids,
		setIds,
		saldo,
		setSaldo
	} = props;

	const [type, setType] = useState('Billetera');
	const types =[{id:1, text: 'Billetera'},{id:2, text: 'Transferencia'}]
	const [liquidaciones, loading] = useGetLiquidaciones(id)
	const formatDate = (fecha) => {return format(fromUnixTime(fecha), "dd 'de' MMMM 'de' yyyy", { locale: es });}

	const [valor, setValor] = useState(0);

	useEffect(()=>{
		if(saldo){
			setValor(saldo);setSpnAmmount(false)
		}else{
			setValor(0)
	}},[saldo])

	const [spnAmmount,setSpnAmmount] = useState(false);
  const [altAmmount,setAltAmmount] = useState('');

  return (
		<>
		<div className='card'>
			<p className='cardTitle'>Liquidar</p>
			<SelectorOp label={'Método'} value={type} setValue={setType} options={types} />
			<Input type={'number'} label={'Valor*'} value={valor} setValue={setValor} 
				alt={altAmmount} spn={spnAmmount} setSpn={setSpnAmmount} fill={'on'} />
            
			{type !== 'Billetera' ?
				<SubirLiquidacion 
					id={id}
					ids={ids}
					setIds={setIds}
					saldo={valor}
					setSaldo={setSaldo}
					setSpnAmmount={setSpnAmmount}
					setAltAmmount={setAltAmmount}
				/>
			:
				<ComissionToWallet
					id={id}
					pagadas={ids}
					saldo={valor}
					setIds={setIds}
					setSaldo={setSaldo}
					setSpnAmmount={setSpnAmmount}
					setAltAmmount={setAltAmmount}
				/>
			}
		</div>
			
		{loading ? <LoadingCard /> : liquidaciones && liquidaciones.length > 0 &&
			<div className="card">
				<p className='cardTitle'>Liquidaciones</p>
				{liquidaciones.map((liquidacion)=>{
					return(
						<div className="BtnBox" key={liquidacion.id}>
							<a className="BtnExports BlueColor btnLarge" href={liquidacion.documento} target="blank_" 
								rel="noreferrer noopener">Comprobante <SVG src={ImgPdf} /></a>
							<PDFDownloadLink 
								fileName={`Liquidación Comisiones ${liquidacion.id.substring(0, 6).toUpperCase()} ${liquidacion.codigo}.pdf`}
								className="BtnExports BlueColor btnLarge"
								document={
									<FacturaComision 
										fecha={formatDate(liquidacion.date)}
										pagadas={liquidacion.comisiones}
										valor={liquidacion.valor}
										nombre={liquidacion.nombre}
										numeroId={liquidacion.numeroId}
										tipoId={liquidacion.tipoId}
									/>
								}>
								{({loading}) =>
									loading ? 'Cargando Documento...' :
									<>Factura <SVG src={ImgPdf} /></>
								}
							</PDFDownloadLink>
						</div>   
					)
				})}
			</div>
		}
		</>
	)
}

export default Liquidaciones;