import jsPDF from 'jspdf';
import React from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { BtnActions } from '../../../../components/buttons/Boton';
import ImgExcel from '../../../../img/icons/document-download.svg';
import ImgPdf from '../../../../img/icons/document-text-1.svg';
import ExportTransacciones from '../functions/ExportTransacciones';
 
const ExportarTransferencias = ({Action}) => {

  const {dateNumbers, Capitalize} = useConfig();

	const Format = (datos) =>{
    const temp =  datos.map((transaccion)=>{
      let payment = transaccion.pagoMetodo
      let comprobante = transaccion.pagoTrans
      let Destino
      let Tipo = transaccion.destinatario.tipo
      if(!Tipo){Tipo = 'Persona'}
      if(Tipo){
        if(Tipo === 'Empresa'){Destino = Capitalize(transaccion.destinatario.nombre)}
        else{Destino = Capitalize(transaccion.destinatario.nombre+' '+transaccion.destinatario.apellido)}
      }
      if(transaccion.pago){
        payment = transaccion.pago.metodo
        comprobante = transaccion.pago.document
      }

      return [
        transaccion.id.substring(0, 6).toUpperCase(),
        transaccion.transferencia.metodo,
        dateNumbers(transaccion.fecha),
        Capitalize(transaccion.remitente.nombre+' '+transaccion.remitente.apellido),
        transaccion.remitente.tipoId,
        transaccion.remitente.numeroId,
        Tipo,
        Destino,
        transaccion.destinatario.tipoId,
        transaccion.destinatario.numeroId,
        transaccion.razon,
        transaccion.transferencia.from ? transaccion.transferencia.from : transaccion.transferencia.totalPagado,
        transaccion.transferencia.cargos,
        transaccion.colcambios.banco,
        transaccion.transferencia.tasa,
        transaccion.transferencia.fee,
        transaccion.transferencia.to ? transaccion.transferencia.to : transaccion.transferencia.montoAud,
        payment,
        transaccion.cuenta.banco,
        transaccion.cuenta.bsb,
        transaccion.cuenta.numeroCuenta,
        Capitalize(transaccion.cuenta.titular),
        transaccion.estado,
        comprobante
      ]
    });
    return temp
  }

  //EXPORTAR A PDF
  const DownloadPDF = () =>{
    Action.then((data)=>{
      const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'tabloid'})
      doc.autoTable({
          head: [["Ticket","Method","Register Date","Sender","Sender ID type","Sender ID","Receiver Type","Receiver","Receiver ID type","Receiver ID",
          "Shipping Reason","Value Paid","Bank Taxes","Colcambios Account","Exchange Rate","Service","Received Amount","Payment Type","Bank",
          "BSB","Account Number","Account Name","Transfer Status"]],
          body: Format(data)
      })
      doc.save('Transferencias.pdf');
    })
  }

  const DownloadExcel = () =>{Action.then((data)=>{ExportTransacciones(Format(data))})}

  return (
    <>
      <BtnActions Clases={'GreenColor'} Action={DownloadExcel} Img={ImgExcel} Text={'Exportar a Excel'}  Disbaled={false} />
      <BtnActions Clases={'RedColor'} Action={DownloadPDF} Img={ImgPdf} Text={'Exportar a PDF'} Disbaled={false} />
    </>
  );
}

export default ExportarTransferencias;