import React from 'react';
import { BtnIcon } from '../../../../components/buttons/Boton';
import { useConfig } from '../../../../context/ConfigsContext';
import ImgDelete from '../../../../img/icons/trash.svg';
import { BorrarAccount } from '../functions/Delete';
 
const SelfAcount = ({cuentas, setCuentas, admin}) => {
 
  const {Capitalize, OpenModal} = useConfig();
  const AbrirBorrar = (e) =>{ e.preventDefault(); OpenModal(<BorrarAccount id={e.currentTarget.dataset.id} cuentas={cuentas} setCuentas={setCuentas} />)}

  return (<>
    <div className="cardsTable">
      {cuentas.sort((a, b) => a.date - b.date).map((cuenta, i) => {
        return(
          <div className='cardTable' key={i}>
            <h3>{cuenta.banco}</h3>
            <div className='infoDiv'>
              <p><label>Titular</label>{Capitalize(cuenta.titular)}</p>
              <p><label>Cuenta</label>{cuenta.cuenta}</p>
              {!admin && <BtnIcon Action={AbrirBorrar} Id={cuenta.indice} Type={'button'} Img={ImgDelete} />}
            </div>
            <div className='infoDiv'>
              <p><label>Banco</label>{cuenta.banco}</p>
              <p><label>BSB</label>{cuenta.bsb}</p>
            </div>
          </div>
        )}
      )}
    </div>
    <div className='tablaGeneralDiv'>
      <table className='tablaGeneral'>
        <thead>
          <tr>
            <th>Pais</th>
            <th>Banco</th>
            <th>Titular</th>
            <th>Bsb</th>
            <th>Cuenta</th>
            {!admin && <th></th>}
          </tr>
        </thead>
        <tbody>
          {cuentas.sort((a, b) => a.date - b.date).map((cuenta, i) => {
            return(
              <tr key={i}>
                <td>{cuenta.pais ? cuenta.pais : 'Australia'}</td>
                <td>{cuenta.banco}</td>
                <td>{cuenta.titular}</td>
                <td>{cuenta.bsb}</td>
                <td>{cuenta.cuenta}</td>
                {!admin && <td><BtnIcon Action={AbrirBorrar} Id={cuenta.indice} Type={'button'} Img={ImgDelete} /></td>}
              </tr>
              )
            })
          }
        </tbody>
      </table> 
    </div>
    </>
  );
}
 
export default SelfAcount;