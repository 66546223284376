import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useConfig } from '../../../context/ConfigsContext';
import { useTrans } from '../../../context/TransferContext';
import { useGetTransfer } from '../../hooks/useConsultas';
import { useObtenerReferido } from "../../hooks/useGetCodes";
import { AgregarReferido, EditarTransferencia, AgregarComision } from "../../data/AdminTransfer";
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import Recotizar from "./components/Recotizacion";
import BorrarComprobante from "./functions/BorrarComprobante";
import ActualizarEstado from "./functions/ActualizarEstado";
import { CargarFactura } from './components/Cargas';
import BuscarReferido from "./components/BuscarReferido";
import FixRazon from "./components/FixRazon";
import Ingreso from "./components/Ingreso";
import Revisiones from "./components/Revisiones";
import { BtnGeneral } from '../../../components/buttons/Boton';
import { ModalValidar } from "../../../components/modales/Modales";
import { LoadingCard } from "../../../components/loading/Loadings";
import SVG from 'react-inlinesvg';
import CardDoc from '../../../components/cards/CardDoc';
import DeletePayment from './functions/DeletePayment';
import Comprobante from '../../../views/pages/transferencia/components/Comprobante';
import { InputDisabled } from '../../../components/inputs/Input';
import ImgX from "../../../img/icons/close-square.svg";
import Whatsapp from '../../../img/icons/whatsapp.svg';
import Eye from '../../../img/icons/export-2.svg';
import "../../../styles/transferencias.css";
import "../../../components/cards/cardTrans.css";
import ExportInvoice from '../../../views/pages/transferencia/functions/ExportInvoice';

const TransferenciaA = () => {
  
  //TRANSFERENCIA
  const { country, id } = useParams();
  const { OpenModal, setProcesando, FormatCop, FormatAud, FormatNzd, Capitalize, horaAU, horaCO, horaNZ, dateAu, dateEmail} = useConfig();
  const {truncate} = useTrans();
  const idNuevo = id.substring(0, 6).toUpperCase();
  const [table, setTable] = useState();

  useEffect(()=>{
    if(country){
      if(country === 'australia'){setTable('transferencias')}
      if(country === 'colombia'){setTable('colombia')}
      if(country === 'newzealand'){setTable('newzealand')}
    }
  },[country])

  const {transferencia, loading} = useGetTransfer(id, table);
  const [fecha, setFecha] = useState();
  const [fechaPago, setFechaPago] = useState();
  const [estadoTrans, setEstadoTrans] = useState();
  const [razonTrans, setRazonTrans] = useState('');
  const [fixRazon, setFixRazon] = useState();
  const [colcambios, setColcambios] = useState();
  const [pagoMetodo, setPagoMetodo] = useState();

  //VALORES
  const [montoAud, setMontoAud] = useState(0);
  const [servicio, setServicio] = useState(0);
  const [total, setTotal] = useState(0);
  const [metodo, setMetodo] = useState(0);
  const [fee, setFee] = useState(0);
  const [cargos, setCargos] = useState(0);
  const [tasadeCambio, setTasadeCambio] = useState(0);
  const [cuatroMil, setCuatroMil] = useState(0);

  //REMITENTE
  const [cliente, setCliente] = useState()
  const [autoEnvio, setAutoEnvio] = useState();
  const [rteAppId, setRteAppId] = useState();
  const [rteNombre, setRteNombre] = useState();
  const [rteApellido, setRteApellido] = useState();
  const [rteTipoId, setRteTipoId] = useState();
  const [rteNoId, setRteNoId] = useState();
  // const [rteDocumento, setRteDocumento] = useState();
  const [rteEmail, setRteEmail] = useState();
  const [rteCelular, setRteCelular] = useState();
  //DESTINATARIO
  const [dnoTipo, setDnoTipo] = useState("Persona");
  const [dnoAppId, setDnoAppId] = useState('');
  const [dnoNombre, setDnoNombre] = useState('');
  const [dnoApellido, setDnoApellido] = useState('');
  const [dnoTipoId, setDnoTipoId] = useState('');
  const [dnoNoId, setDnoNoId] = useState('');
  const [dnoDocumento, setDnoDocumento] = useState('');
  const [dnoCelular, setDnoCelular] = useState('');
  const [dnoEmail, setDnoEmail] = useState('');
  //CUENTA
  const [titular, setTitular] = useState('');
  const [nombreBanco, setNombreBanco] = useState('');
  const [numeroBSB, setNumeroBSB] = useState('');
  const [numerodeCuenta, setNumerodeCuenta] = useState('');
  const [tipoCuenta, setTipoCuenta] = useState('');
  //CODIGOS REFERIDO
  const [referency, setReferido] = useState("");
  const [codigoRef, setCodigoRef] = useState();
  const [asignado, setAsignado] = useState(false);
  const [negociacion, setNegociacion] = useState();
  const [referido] = useObtenerReferido(codigoRef);
  //CODIGO PROMOCIONAL
  const [proCodigo, setProCodigo] = useState("");
  const [proTipo, setProTipo] = useState("");
  const [proDescuento, setProDescuento] = useState("");
  const [proAgenciaId, setProAgenciaId] = useState("");
  //RECOTIZAR
  const [observaciones, setObservaciones] = useState();
  //DOCUMENTOS
  const [comprobantePago, setComprobantePago] = useState();
  const [factura, setFactura] = useState(false);
  const [pagoTrans, setPagoTrans] = useState();
  const [pagoDate, setPagoDate] = useState('');
  //REVISIONES
  const [nivel1, setNivel1] = useState(false);
  const [nivel2, setNivel2] = useState(false);
  const [nivel3, setNivel3] = useState(false);

  const CoinTo = (number) =>{
    if(table === 'transferencias'){return FormatAud(number)}
    if(table === 'colombia'){return FormatCop(number)}
    if(table === 'newzealand'){return FormatNzd(number)}
  }

  const CoinFrom = (number) =>{
    if(table === 'transferencias'){return FormatCop(number)}
    if(table === 'colombia'){return FormatAud(number)}
    if(table === 'newzealand'){return FormatCop(number)}
  }

  const CoinFee = (number) =>{
    if(table === 'transferencias'){return FormatAud(number)}
    if(table === 'colombia'){return FormatAud(number)}
    if(table === 'newzealand'){return FormatNzd(number)}
  }

  //FUNCIONES
  useEffect(() => {
    if (transferencia) {
      setFecha(transferencia.fecha);
      setFechaPago(transferencia.fechaMaxPago);
      setEstadoTrans(transferencia.estado);
      setRazonTrans(transferencia.razon);
      if(transferencia.fixRazon){setFixRazon(transferencia.fixRazon)}
      setMontoAud(transferencia.transferencia.to ? transferencia.transferencia.to : transferencia.transferencia.montoAud);
      setServicio(transferencia.transferencia.servicio);
      setTotal(transferencia.transferencia.from ? transferencia.transferencia.from : transferencia.transferencia.totalPagado);
      setMetodo(transferencia.transferencia.metodo);
      setFee(transferencia.transferencia.fee);
      setCargos(transferencia.transferencia.cargos);
      setTasadeCambio(transferencia.transferencia.tasa);
      setCuatroMil(transferencia.transferencia.cuatromil)

      setAutoEnvio(transferencia.autoEnvio);
      setRteAppId(transferencia.usuario);
      setRteNombre(transferencia.remitente.nombre);
      setRteApellido(transferencia.remitente.apellido);
      setRteTipoId(transferencia.remitente.tipoId);
      setRteNoId(transferencia.remitente.numeroId);
      // setRteDocumento(transferencia.remitente.documento);
      setRteEmail(transferencia.remitente.email);
      setRteCelular(transferencia.remitente.celular);

      if (transferencia.destinatario.tipo) {
        setDnoTipo(transferencia.destinatario.tipo);
      }
      setDnoAppId(transferencia.destinatarioId);
      setDnoNombre(transferencia.destinatario.nombre);
      setDnoApellido(transferencia.destinatario.apellido);
      setDnoTipoId(transferencia.destinatario.tipoId);
      setDnoNoId(transferencia.destinatario.numeroId);
      setDnoDocumento(transferencia.destinatario.documento);
      setDnoCelular(transferencia.destinatario.celular);
      setDnoEmail(transferencia.destinatario.email);

      setTitular(transferencia.cuenta.titular);
      setNombreBanco(transferencia.cuenta.banco);
      setNumeroBSB(transferencia.cuenta.bsb);
      setNumerodeCuenta(transferencia.cuenta.numeroCuenta);
      setTipoCuenta(transferencia.cuenta.tipo)
      setColcambios(transferencia.colcambios)

      setObservaciones(transferencia.observaciones);

      setComprobantePago(transferencia.comprobantePago);
      setFactura(transferencia.factura);

      if(transferencia.pago){
        setPagoDate(transferencia.pago.date)
        setPagoTrans(transferencia.pago.document);
        setPagoMetodo(transferencia.pago.metodo)
      }else{
        setPagoTrans(transferencia.pagoTrans);
        setPagoMetodo(transferencia.pagoMetodo)
      }
      
      if (transferencia.referido) {
        setCodigoRef(transferencia.referido);
        setAsignado(transferencia.comision);
      }

      // if (transferencia.revisada) {
      //   setRevisada(transferencia.revisada);
      // }

      if(transferencia.cliente){ setCliente(transferencia.cliente)}

      if(transferencia.revisiones){
        setNivel1(transferencia.revisiones.datos)
        setNivel2(transferencia.revisiones.valores)
        setNivel3(transferencia.revisiones.ingreso)
      }
      if(transferencia.promociones) {
        setProTipo(transferencia.promociones.tipo);
        setProCodigo(transferencia.promociones.codigo);
        setProDescuento(transferencia.promociones.descuento);
        setProAgenciaId(transferencia.promociones.agenciaId);
      }
    }
  }, [transferencia]);

  useEffect(() => {if(referido){setNegociacion(referido);}}, [referido]);

  //CANCELAR TRANSFERENCIA
  const AbrirCancelar = (e) => {
    e.preventDefault();
    OpenModal(
      <ModalValidar
        accion={TransCancelada}
        titulo={"Cancelar Transferencia"}
        mensaje={"¿Estás seguro de Cancelar esta transferencia?"}
        botonName={"Sí, Cancelar"}
        botonClass={"btnGeneral redC"}
      />
    );
  };

  const TransCancelada = () => {
    setProcesando(true)
    EditarTransferencia({
      dbTable: table,
      id: id,
      estado: "Cancelada",
      factura: false,
    }).then(async() => {
        const datos = {
          id: idNuevo,
          fecha: dateEmail(fecha).toUpperCase(),
          estado: 'Cancelada',
          nombre: Capitalize(rteNombre),
          email: rteEmail,
          metodo: metodo,
          tasa: FormatCop(tasadeCambio),
          aud: FormatAud(montoAud),
          cop: FormatCop(total),
          razon: razonTrans,
          titular: Capitalize(titular),
          bank: nombreBanco,
          bsb: numeroBSB,
          account: numerodeCuenta,
          msj: 'fue Cancelada desafortunadamente. A continuación encontrarás su información.'
        } 

        await axios.post('https://server-colcambios.herokuapp.com/api/transferencia',{datos})
          .then((res) => {
            if (res.data.success) {
              setProcesando(false)
              OpenModal(<PopUpValid mensaje={'La transferencia fue cancelada correctamente.'}/>);
              setEstadoTrans("Cancelada");
              setFactura(false);
            }
          })
          .catch(() => {
            setProcesando(false)
            OpenModal(<HuboUnError />);
          });
      })
      .catch(() => {
        setProcesando(false)
        OpenModal(<HuboUnError />);
      });
  };

  //ASIGNAR REFERIDO
  const AbrirReferido = (e) => {
    e.preventDefault();
    if (!referency) {return;}
    OpenModal(
      <ModalValidar
        accion={GuardarReferido}
        titulo={"Asignar Referido"}
        mensaje={"¿Estás seguro de asignar la referencia?"}
        botonName={"Sí, Referenciar"}
        botonClass={"btnGeneral primaryC"}
      />);
  };

  const GuardarReferido = () => {
    setProcesando(true);
    AgregarReferido({
      dbTable: table,
      id: id,
      referido: referency,
    }).then(() => {
      setProcesando(false);
      OpenModal(<PopUpValid mensaje={'El Referido se ha agregado correntamente'} />);
    }).catch(() => {
      OpenModal(<HuboUnError />);
    });
  };

  //ELIMINAR COMPROBANTE
  const AbrirEliminar = (e) => {
    e.preventDefault();
    OpenModal(
      <BorrarComprobante
        dbTable={table}
        id={id}
        idNuevo={idNuevo}
        rteAppId={transferencia.empresa ? transferencia.empresa : transferencia.usuario}
        rteEmail={rteEmail}
        rteNombre={rteNombre}
        titular={titular}
        banco={nombreBanco}
        bsb={numeroBSB}
        cuenta={numerodeCuenta}
        total={total}
        metodo={metodo}
        razonTrans={razonTrans}
        aud={montoAud}
        tasa={tasadeCambio}
        fechaTrans={fecha}
        comprobantePago={comprobantePago}
        setEstadoTrans={setEstadoTrans}
        setComprobantePago={setComprobantePago}
        empresa={transferencia.empresa ? true : false}
      />
    );
  };

  const EliminarPago = (e) =>{
    e.preventDefault();
    OpenModal(
      <DeletePayment 
        dbTable={table}
        id={id}
        empresa={transferencia.empresa ? true : false}
        rteAppId={transferencia.empresa ? transferencia.empresa : transferencia.usuario}
        setEstadoTrans={setEstadoTrans}
        pagoTrans={pagoTrans}
        setPagoTrans={setPagoTrans}
        setPagoMetodo={setPagoMetodo}
      />
    )
  }

  //ASIGNAR COMISION
  const AsignarComision = () => {
    OpenModal(
      <ModalValidar
        accion={AddComision}
        titulo={"Asignar Comisión"}
        mensaje={"¿Estás seguro de asignar esta comisión?"}
        botonName={"Sí, Asignar"}
        botonClass={"btnGeneral primaryC"}
    />);
  };

  const AddComision = () => {
    setProcesando(true);
    AgregarComision({
      dbTable: table,
      referido: codigoRef,
      comision: truncate((montoAud * 0.0025),2),
      fecha: fecha,
      nombre: rteNombre + " " + rteApellido,
      referencia: id,
      estado: "pendiente",
    }).then(() => {
      setProcesando(false);
      OpenModal(<PopUpValid mensaje={'La comisión se ha agregado correctamente'} />);
      setAsignado(true);
    }).catch(() => {
      setProcesando(false);
      OpenModal(<HuboUnError />);
    });
  };

  return (
    <div className='dashPage'>
      <Helmet><title>Transferencia</title></Helmet>
      <p className='pageTitle'>Transferencia</p>

      {loading ? <LoadingCard /> : 
        <>
          <div className="cardFlex">
            <div className="card70">

              <div className="cardTransfer">
                <div className="NTransfer"><p><span>Referencia</span>{idNuevo}</p></div>
                <div className="STransfer">
                  <p><span>Estado</span>{estadoTrans}</p>
                  <p><span>Método</span>{metodo}</p>
                </div>
                {country === 'australia' && <>
                  <div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(fecha)}</p></div>
                  <div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(fecha)}</p></div>
                </>}
                {country === 'colombia' && <>
                  <div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(fecha)}</p></div>
                  <div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(fecha)}</p></div>
                </>}
                {country === 'newzealand' && <>
                  <div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(fecha)}</p></div>
                  <div><p className="FTransfer"><span>Fecha en Nueva Zelanda</span>{horaNZ(fecha)}</p></div>
                </>}
                
              </div>

              <div className="card">
                <p className='cardTitle'>Información</p>

                <div className='cardsDataUser'>
                  <div className="cardDataUser">
                    <h2>{Capitalize(rteNombre+ " " +rteApellido)}<span>Remitente</span></h2>
                    <p><span>{rteTipoId}</span>{rteNoId}</p>
                    <p><span>Celular</span>{rteCelular}</p>
                    <div>
                      <a href={`https://wa.me/${rteCelular}`} target="blank_" rel="noopener noreferrer" className='btnWhatsapp'><SVG src={Whatsapp} /></a>
                      <Link to={`/usuario/${rteAppId}`} className='btnLink'><SVG src={Eye} /></Link>
                    </div>
                  </div>

                  {!autoEnvio &&
                    <div className="cardDataUser">
                      <h2>{!autoEnvio ? Capitalize(dnoNombre + " " + dnoApellido) : 'AutoEnvio'}<span>{dnoTipo}</span></h2>
                      <p><span>{dnoTipoId}</span>{dnoNoId}</p>
                      <p><span>Celular</span>{dnoCelular}</p>
                      <div>
                        <a href={`https://wa.me/${dnoCelular}`} target="blank_" rel="noopener noreferrer" className='btnWhatsapp'><SVG src={Whatsapp} /></a>
                        <Link to={`/destinatario/${dnoAppId}`} className='btnLink'><SVG src={Eye} /></Link>
                      </div>
                    </div>
                  }

                  <div className='cardDataUser'>
                    <h2>{Capitalize(titular)}<span>Titular</span></h2>
                    <p><span>Banco</span>{nombreBanco}</p>
                    {table === 'transferencias' && <p><span>BSB</span>{numeroBSB}</p>}
                    {table === 'colombia' && <p><span>Tipo de Cuenta</span>{tipoCuenta}</p>}
                    <p><span>No. de Cuenta</span>{numerodeCuenta}</p>
                  </div>
                </div>

                <InputDisabled label={'Razón del Envío'} value={razonTrans} setValue={setRazonTrans} />
                <FixRazon dbTable={table} id={id} text={fixRazon}/>
              </div>
              {cliente &&
							<div className='card'>
								<p className='cardTitle'>Cliente</p>
								<div className="cardData">
									<div><p><label>Nombre</label>{cliente.nombre}</p></div>
									<div><p><label>Tipo ID</label>{cliente.tipoDoc}</p></div>
									<div><p><label>Número de ID</label>{cliente.numeroDoc}</p></div>
									<div><p><label>Celular</label>{cliente.phone}</p></div>
									<div><p><label>Email</label>{cliente.email}</p></div>
								</div>
							</div>
						}

            {!comprobantePago &&
              <Comprobante
                dbTable={table}
                colcambios={colcambios}
                fechaMaxPago={fechaPago} 
                fechaTransfer={fecha}
                docRef={id}
                uidUsuario={transferencia.empresa ? transferencia.empresa : transferencia.usuario} 
                rteEmail={rteEmail}
                rteNombre={transferencia.remitente.nombre}
                banco={nombreBanco}
                bsb={numeroBSB}
                titular={titular}
                cuenta={numerodeCuenta}
                total={total}
                emailAud={montoAud} 
                emailTasa={transferencia.transferencia.tasa} 
                razon={razonTrans}
                metodo={metodo}
                empresa={transferencia.empresa ? true : false}
              />
            }

            <div className="card">
              <p className='cardTitle'>Documentos</p>
            
              <div className="cardDocuments">
                {/* <a href={rteDocumento} target="blank_" rel="noreferrer noopener">Id Remitente<br/><img src={DocId} alt="Documento" /></a> */}
                {!autoEnvio && <CardDoc documento={dnoDocumento} label={'Destinatario ID'} trash={false} action={()=>{}} 
                  text={dnoNombre+' '+dnoApellido} />}
                {comprobantePago && 
                  <CardDoc documento={comprobantePago} label={'Comprobante'} trash={!pagoTrans ? true : false} action={AbrirEliminar} 
                    text={colcambios.banco}
                  />}
                {pagoTrans && 
                  <CardDoc documento={pagoTrans} label={'Pago'} trash={true} action={EliminarPago} text={pagoMetodo+' '+ dateAu(pagoDate)}/>}
              </div>
              
            </div>

            {!pagoTrans && 
              <CargarFactura
                dbTable={table} 
                fechaEmail={fecha} 
                id={id} 
                rteAppId={transferencia.empresa ? transferencia.empresa : transferencia.usuario} 
                idNuevo={idNuevo} 
                autoEnvio={autoEnvio} 
                rteEmail={rteEmail}
                rteNombre={rteNombre}
                emailTasa={tasadeCambio}
                total={total}
                banco={nombreBanco}
                bsb={numeroBSB}
                titular={titular}
                cuenta={numerodeCuenta}
                metodo={metodo}
                razonTrans={razonTrans}
                destinatario={dnoNombre}
                dnoEmail={dnoEmail}
                rteApellido={rteApellido}
                codigoRef={codigoRef}
                montoAud={montoAud}
                fechaUnix={fecha}
                asignado={asignado}
                empresa={transferencia.empresa ? true : false}
              />
            }

            {proTipo && <div className="card">
              <p className='cardTitle'>Códigos</p>
                <div className="cardData">
                  <div><p><label>Tipo</label>{proTipo}</p></div>
                  <div><p><label>Código</label>{proCodigo}</p></div>
                  <div><p><label>Descuento</label>{proDescuento}</p></div>
                  {proAgenciaId && <div className="col-6 col-sm-4"><p><label>Agencia</label>{proAgenciaId}</p></div>}
                </div>
            </div>
            }

            {table === 'transferencias' &&
              <div className="card">
                <p className='cardTitle'>Referido</p>
                {codigoRef && <div className="cardData">
                  <div><p><label>Código</label>{codigoRef}</p></div>
                  <div><p><label>Negociación</label>{negociacion}</p></div>
                  {estadoTrans === "Procesada" && !asignado && 
                    <BtnGeneral text='Asignar Comisión' img={''} side={'L'} disabled={false} type="button" 
                      action={AsignarComision} clases={'primaryC'} large={false} />
                  }
                </div>}
                {!codigoRef && <>
                  <BuscarReferido setReferido={setReferido} />
                  {referency && 
                    <BtnGeneral text='Guardar Cambios' img={''} side={'L'} disabled={false} type="button" 
                    action={AbrirReferido} clases={'primaryC'} large={false} />}
                </>}
              </div>
            }

            {observaciones && (
              <div className="card">
                <p className='cardTitle'>Observaciones</p>
                <div className="textP"><p>{observaciones}</p></div>
              </div>
            )}
          </div>

            <div className="card30">
              <Revisiones dbTable={table} id={id} 
                nivel1={nivel1} setNivel1={setNivel1}
                nivel2={nivel2} setNivel2={setNivel2}
                nivel3={nivel3} setNivel3={setNivel3}
              />

              <div className="card">
                <p className='cardTitle'>Resumen</p>
                <div className="cardResumen">
                  <div><label>Valor Pagado</label><span>{CoinFrom(total)}</span></div>
                  <div><label>Cargos Bancarios</label><span>{cargos !== 0 && "- "}{CoinFrom(cargos)}</span></div>
                  {table !== 'colombia' && <div><label>Impuesto 4x1000</label><span>{FormatCop(cuatroMil)}</span></div>}
                  {table === 'colombia' && <div><label>Servicio</label><span>{!servicio && "- "}{CoinFee(fee)}</span></div>}
                  <div><label>Tasa de Cambio</label><span>{FormatCop(tasadeCambio)}</span></div>
                  {table !== 'colombia' && <div><label>Servicio</label><span>{!servicio && "- "}{CoinFee(fee)}</span></div>}
                  {table === 'colombia' && <div><label>Impuesto 4x1000</label><span>{FormatCop(cuatroMil)}</span></div>}
                  <div><label>Valor a Recibir</label><span><b>{CoinTo(montoAud)}</b></span></div>
                </div>
              </div>

              <Ingreso 
                id={id} 
                nombre={rteNombre}
                apellido={rteApellido}
                referency={'transferencia'}
                dbTable={table === 'colombia' ? 'ingresosAu' : 'ingresos'}
              />

              {estadoTrans !== "Procesada" &&
                <Recotizar 
                  dbTable={table} 
                  FormatTo={CoinTo}
                  FormatFrom={CoinFrom}
                  FormatFee={CoinFee}
                  pagado={total}
                  setPagado={setTotal}
                  cargos={cargos}
                  setCargos={setCargos}
                  cuatroMil={cuatroMil}
                  setCuatroMil={setCuatroMil}
                  tasa={tasadeCambio}
                  setTasa={setTasadeCambio}
                  metodo={metodo}
                  setMetodo={setMetodo}
                  fee={fee}
                  setFee={setFee}
                  montoAud={montoAud}
                  setMontoAud={setMontoAud}
                  id={id} idNuevo={idNuevo}
                  rteEmail={rteEmail}
                  rteNombre={rteNombre} 
                  dnoNombre={dnoNombre}
                  dnoApellido={dnoApellido}
                  razonTrans={razonTrans}
                  setObs={setObservaciones}
                  titular={titular}
                  banco={nombreBanco}
                  bsb={numeroBSB}
                  cuenta={numerodeCuenta}
                />
              }

              <ExportInvoice
                transferencia={transferencia}
                table={table}
                factura={factura}
                id={id}
              />

              <ActualizarEstado dbTable={table} id={id} estadoTrans={estadoTrans} />
            </div>
          </div>
          {estadoTrans !== "Cancelada" && estadoTrans !== "Procesada" &&
              <div className="card">
                <div>
                  <BtnGeneral text='Cancelar Transferencia' img={ImgX} side={'L'} disabled={false} type="button" 
                    action={AbrirCancelar} clases={'redC'} large={false} />
                </div>
              </div>
            }
        </>
      }
    </div>
  );
};

export default TransferenciaA;
