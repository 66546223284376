import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../context/AuthContext';
import { useObtenerDestinatarios } from '../../hooks/useGetReceivers';
import TablaDestUser from '../../../components/tablas/TablaDestUser';
import { BtnLink, BtnLinkAction } from '../../../components/buttons/Boton';
import ImgPlus from '../../../img/icons/plus.svg';
import DestinatarioPublico from './DestinatarioPublico';
import '../../../styles/table.css';
import SelfAcount from './components/SelfAcount';

const Destinatarios = () => {

  const {infoUser} = useAuth();
  const {contactos, loading} = useObtenerDestinatarios();
  const [cuentas, setCuentas] = useState([]);
  const [rol, setRol] = useState('');
  
  useEffect(()=>{
    if(infoUser){
      setRol(infoUser.rol)
      if(infoUser.rol === 'Usuario' || infoUser.rol === 'admin' || infoUser.rol === 'asistente' || infoUser.rol === 'empresa'){
        if(infoUser.cuentas){
          const results = Object.keys(infoUser.cuentas).map(key => 
            ({...infoUser.cuentas[key], indice: key}))
          setCuentas(results)
        }
      }
    }
  },[infoUser])

  return (
    <div className='dashPage'>
      <Helmet><title>Destinatarios</title></Helmet>
      <p className='pageTitle'>Destinatarios</p>

      <div className="card">
        <p className="cardTitle">Destinatarios</p>
        <div className='TableHead'>
          {contactos.length > 0 && rol !== 'asesor' &&
            <BtnLinkAction link={'/nuevo-destinatario'} large={false} img={ImgPlus} side={'L'} text={'Nuevo Destinatario'} clases={'BlueColor'} />} 
        </div>
        <TablaDestUser datos={contactos} loading={loading} />
      </div>

      {rol !== 'asesor' &&
        <div className="card">
          <p className="cardTitle">Cuentas de AutoEnvío</p>
          <div className='TableHead'>
            {cuentas.length > 0 &&
            <BtnLinkAction link={'/nuevo-destinatario?type=cuentaPropia'} large={false} img={ImgPlus} side={'L'} 
              text={'Agregar Cuenta'} clases={'BlueColor'} /> }
          </div>
            
          {cuentas.length > 0 ?  <SelfAcount cuentas={cuentas} setCuentas={setCuentas} admin={false} />
            :   
              <div className="itemsEmpty">
                <p>Aún no has ingresado tus cuentas</p>
                <BtnLink link="/nuevo-destinatario"  clases='primaryC' large={false} img={ImgPlus} side='L' text='Agregar Cuenta' />
              </div>
            }
        </div>
      }
      
      {rol === 'empresa' && <DestinatarioPublico />}
    </div>
  );
}

export default Destinatarios;